.main-overview{
    background-color: #efefef;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
    text-align: left;
    padding: 10px;
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-size: 1vw;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
}

.title{
  color: #236382;
  font-family: Merriweather;
  font-size: 3rem;
  margin: 0px;
  padding: 10px 5px;
}
.cards{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap:10px;
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
}
.cards.one-column{
  grid-template-columns: 1fr;
}.cards.one-row{
  grid-template-rows: 1fr;
}
.cards .card{
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
    padding:20px;
    flex-grow: 1;
    overflow-x: scroll  ;
      
}
.card.chart-div{
  grid-column: 1/3;
}
.card .button-left.align-bottom{
    margin-top: auto!important;
  }
.subtitle{
    color: #236382;
    font-family: Merriweather;
    font-size: 1.5rem;
    font-weight: 600;
}
.big-text-blue{
    color: #236382;
    font-size: 3vw;
    font-weight: 600;
    text-align: center;
}
.medium-text-grey{
    color: #c2c2c2;
    font-size: 2vw;
    font-weight: 600;
    text-align: center;
}
.small-text-blue{
    color: #236382;
    font-size: 2vw;
    font-weight: 600;
    text-align: center;
}
.smaller-text-blue{
  color: #236382;
  font-size: 1.5vw;
  font-weight: 600;
  text-align: left;

  padding-bottom: 10px;
}
.small-text-grey{
    color: #c2c2c2;
    font-size: 1vw;
    font-weight: 600;
    text-align: center;}

.button-left{
    display: flex;
    justify-content: flex-end;
}

.text12{
  font-size: 1.1vw;
  display: inline;
}
@media only screen and (max-width: 900px) {
  .main-overview{
        font-size: 2vw;
        margin-top: 80px;
    }
  .cards {
    grid-template-columns: 1fr;
  }
  .cards .card{
    width: auto;
  }
  .card.chart-div{
    grid-column: 1/2;
  }
  .big-text-blue{
    font-size: 30px;
  }
  .title{
    font-size:30px
  }
  .subtitle{
    font-size:20px
  }
  .small-text-blue{
    font-size:20px;
  }
  .smaller-text-blue{
    font-size:20px;
  }
  .small-text-grey{
    font-size:20px;
  }
  .medium-text-grey{
    font-size: 25px;;
  }
  .button-left{
    justify-content: inherit;
  }
  .button-left Button{
    font-size: 15px;
  }
  .chart-div{
    min-height: 300px;
    
  }
  .subtitle-and-dropdown{
    align-items: center;
  }
  .text12{
    font-size: 15px;
  }
}