#main-menu-div{
    background-color: white;
    position: static;
    height:100vh;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}
.menu-category{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    flex-grow: 1;
    text-decoration: none;
}
.menu-category *{
    text-decoration: inherit;
    font-size: 1rem;
    font-weight:700;
}
.contact-us{
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
  padding: 0.7rem 1.5rem;
  text-align: center;
  margin-top: auto;
  margin-bottom: 0.7rem;
  align-self: center;
}
.formatted-link{
    text-wrap: nowrap;
}

@media only screen and (max-width: 900px) {
    #main-menu-div *{
        font-weight: 700;
        font-size: 1rem;
    }
    .formatted-link{
        text-wrap: wrap;
    }
}