th{
font-weight:800;
}

td{
    padding: 3px 0px;
}
table{
    border-collapse:collapse;
    margin-bottom: 0.2rem;
}

.clickable:hover{
    cursor: pointer;
}  
td,th{
    font-size: 1rem;
}
@media only screen and (max-width: 900px) {
    td,th{
        font-size: 1rem;
    }
    
}