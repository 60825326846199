.css-1v5u2yv {
    padding: 0px !important;
}
.css-uyfmvt-MuiPaper-root-MuiAppBar-root {
    background-color: #236382 !important;
}
.css-1yyrush{
    background-color: #236382 !important;

}
.spacer{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
}
@media only screen and (min-width: 901px) {
    .mobile-menu{
        display: none !important;
    }
}