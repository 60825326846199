.search-bar-main{
    width: auto;
    background-color: #236382;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 10px 0px ;
    padding-right: 20px;
}

.wlc-notif-avatar{
    display: flex;
    justify-content:space-around;
    gap:25px;
    align-items: center;

}
.wlc-notif-avatar .wlc{
        color: #ffffff;
        font-size: 1.5vw;
        font-family: "Francois One", sans-serif;
        font-weight: 500;
      
}
.fake-button:hover{
    cursor: pointer;
    opacity:0.8;
}
.wlc-notif-avatar .notif:hover{
    cursor: pointer;
    opacity:0.8;
}
.search-bar-input{
    width: 35%;
}
.hide-when-wide{
    display: none;
}
@media(max-width:900px){
    .search-bar-input{
        display: none;
    }
    .hide-when-wide{
        display: block;
    }
    .desktop-search{
        display: none;
    }
    .search-bar-main{
        top:auto;
        left: auto;
        box-shadow: none;
        width:100%;
    }
    .wlc{
        display: none;
    }
    .fake-button{
        display: none;
    }
    .laptop-search{
        display: none;
    }
  }
  