@font-face{
  font-family: Merriweather;
  src: url('fonts/Merriweather/Merriweather-Black.ttf');
 }

@font-face {
  font-family: Sans;
  src: url('fonts/Source_Sans_3/static/SourceSans3-Regular.ttf');
 }
.App {
  text-align: center;
  display: flex;
  width: 100%;
  background-color: #E2E2E2;
}
.App *{
  color: black;
  font-family: Sans;
  font-size: 1rem;

}
.like-mui-joy-input {
  border: 1px solid #d0d7de;
  border-radius: 8px;
  padding: 8px 12px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.like-mui-joy-input:focus {
  border-color: #007fff; /* Primary blue */
  box-shadow: 0 0 0 3px rgba(0, 127, 255, 0.25);
  outline: none;
}

@media(max-width:900px){
  .desktop{
      display: none !important;;
  }
}
