.black-ref{
    color: black;
}
.black-ref:hover{
    color: grey;
    cursor: pointer;
}
.black-text-children *{
    color: black;
}

.white-ref .MuiTypography-root{
    color: white;
}
