.smaller-blue-subtitle{
    color:#236382
}
.two-col-grid{
    margin: 1.2rem 0rem;
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1.5rem;
    justify-items: start;
    align-items: center;


}