.main-signin{
width: 100vw;
height: 100vh;
display: flex;
justify-content:center;
align-items: center;
background-color:  black;
background-image: url('../../../public/mountain.jpg');
background-repeat: no-repeat, no-repeat;
background-position: bottom, left; 
overflow-y: scroll;
}
.hidden{
    display: none;
}
.main-signin *{
    color: white;
    font-size: 1.2rem;
}
.floaty-middle{
    background-color: rgba(0,0,0,0.4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 30vw;
    min-height: 50vh;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.1);
    height: fit-content;
    padding: 25px;
    text-align: left;
}

.sign-up-form{
    text-align: left;
    margin:20px;
    min-width: 40vw;
}

.hidden{
    display: none;
}
.black-descendents *{
    color: black!important;
}